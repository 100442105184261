import { FC, SVGAttributes } from "react";

const KlarnaIconWhite: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="96"
    height="40"
    viewBox="0 0 96 40"
    {...props}
  >
    <defs>
      <path
        id="vp4fpt7xka"
        d="M56.648 4.258c1.134 0 2.186.345 3.057.932V4.54h3.084v10.18h-3.084v-.651c-.871.587-1.923.932-3.057.932-3.002 0-5.436-2.405-5.436-5.371 0-2.967 2.434-5.371 5.436-5.371zm-32.538 0c1.134 0 2.186.345 3.057.932V4.54h3.084v10.18h-3.084v-.651c-.871.587-1.923.932-3.057.932-3.003 0-5.436-2.405-5.436-5.371 0-2.967 2.433-5.371 5.436-5.371zm41.954 6.85c1.07 0 1.936.856 1.936 1.913 0 1.056-.867 1.912-1.936 1.912-1.07 0-1.936-.856-1.936-1.912 0-1.057.867-1.913 1.936-1.913zM3.418 0v14.722H0V0h3.418zm8.538 0c0 3.187-1.257 6.152-3.497 8.356l4.726 6.367H8.962l-5.135-6.92 1.326-.98C7.35 5.196 8.61 2.709 8.61 0h3.345zm23.067 4.539v1.326c.618-.795 1.77-1.325 3.023-1.325v2.962l-.037-.002c-1.22 0-2.98.862-2.98 2.466v4.752h-3.164V4.54h3.158zm10.708-.274c2.485 0 4.391 1.611 4.391 3.98v6.473h-3.08v-5.37c0-1.485-.78-2.285-2.135-2.285-1.265 0-2.315.758-2.315 2.306v5.35h-3.11V4.538h3.072v1.147c.78-1.043 1.945-1.42 3.177-1.42zM17.41.003v14.715h-3.228V.003h3.228zM56.9 7.01c-1.543 0-2.794 1.172-2.794 2.619 0 1.446 1.25 2.619 2.794 2.619s2.795-1.173 2.795-2.62c0-1.446-1.251-2.618-2.795-2.618zm-32.538 0c-1.543 0-2.795 1.172-2.795 2.619 0 1.446 1.252 2.619 2.795 2.619 1.544 0 2.795-1.173 2.795-2.62 0-1.446-1.252-2.618-2.795-2.618z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-944 -864) translate(944 864) translate(14 13)">
        <mask id="9d2c5jq0eb" fill="#fff">
          <use xlinkHref="#vp4fpt7xka" />
        </mask>
        <path
          fill="#FFF"
          d="M-5 -5L73 -5 73 20 -5 20z"
          mask="url(#9d2c5jq0eb)"
        />
      </g>
    </g>
  </svg>
);

export { KlarnaIconWhite };
